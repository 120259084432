@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@100;200;300;400;500;600;700;800;900&display=swap");

/* Make clicks pass-through */
#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: #2563eb;

  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;

  width: 100%;
  height: 2px;
}

/* Fancy blur effect */
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px #2563eb, 0 0 5px #2563eb;
  opacity: 1;

  -webkit-transform: rotate(3deg) translate(0px, -4px);
  -ms-transform: rotate(3deg) translate(0px, -4px);
  transform: rotate(3deg) translate(0px, -4px);
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}

@-webkit-keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes nprogress-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@layer base {
  html,
  body {
    background-color: #fafafa;
    overflow-x: hidden;
  }
}

@layer components {
  .li-availability {
    @apply flex space-x-6 items-center justify-between;
  }
  .availability-input-wrapper {
    @apply flex items-center space-x-2 justify-start;
  }
  .availability-label {
    @apply font-Lexend font-bold text-xs w-10 uppercase;
  }
}

.react-calendar {
  width: 70% !important;
  border: 0px !important;
  background-color: transparent !important;
  font-family: "Lexend", sans-serif !important;
}

abbr:where([title]) {
  text-decoration: none !important;
}
.react-calendar__tile--active {
  background-color: #2563eb !important;
}

.react-calendar button {
  border-radius: 10px;
  font-size: 1.2rem;
  padding: 16px !important;
  width: fit-content !important;
}

.react-calendar__navigation {
  margin-bottom: 0 !important;
}

.react-calendar__tile--now {
  background-color: #2563eb71 !important;
}

react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background-color: #2563eb !important;
}

.react-calendar {
  width: 100% !important;
  line-height: 2rem !important;
}

.react-calendar__tile {
  line-height: 50px !important;
}
.react-calendar__tile:disabled {
  text-decoration: line-through;
  background-color: transparent !important;
}

#home {
  background-image: url("./assets/images/bg.png");
  object-fit: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

#booking {
  background-image: url("./assets/images/booking-bg.svg");
  object-fit: contain;
  background-repeat: no-repeat;
  background-position: center center;
}
